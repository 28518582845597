<template>
  <v-card flat>
    <v-card-title>New Health Blog</v-card-title>
    <v-form @submit.prevent="submit">
      <v-card-text>
        <v-text-field
          outlined
          label="Title"
          v-model="create.title"
          maxLength="60"
        ></v-text-field>
        <vue-editor
          v-model="create.details"
          placeholder="Put your details here"
        ></vue-editor>
      </v-card-text>
      <v-card-actions style="padding: 0 16px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon style="margin-right: 10px" v-on="on" color="gray">
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span>
            If true, this will be made public to all readers, otherwise will be
            available to you only until you make publish true
          </span>
        </v-tooltip>
        <v-switch
          v-model="create.publish"
          :label="`Publish: ${create.publish}`"
        ></v-switch>

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="$v.create.$invalid"
          type="submit"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  mixins: [Utils, validationMixin],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    create: {
      title: null,
      details: null,
      publish: false,
    },
  }),
  validations: {
    create: {
      title: { required, maxLength: maxLength(60) },
      details: { required },
    },
  },
  created() {},
  methods: {
    submit() {
      console.log("sub", this.create);

      this.snack.bar = true;
      let self = this;
      Restful.blogs
        .create(this.create)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
  },
};
</script>
